import type {
    CmsElementImage,
    CmsElementManufacturerLogo,
} from "@shopware-pwa/composables-next";
import { useCmsElementImage as useCmsElementImageOriginal} from "@shopware-pwa/composables-next";
import {useCmsElementConfig} from "#build/imports";

export const useCmsElementImage = (content: CmsElementImage | CmsElementManufacturerLogo) => {
    const { getConfigValue } = useCmsElementConfig(content);
    const { mdAndUp } = useDisplay();

    const useData = useCmsElementImageOriginal(content);

    const maxHeight = getConfigValue("maxHeight")
    if (useData.displayMode.value === 'contain' && typeof maxHeight !== 'undefined') {
        useData.containerStyle.value.maxHeight = maxHeight;
        useData.containerStyle.value.minHeight = undefined
    }

    return {
        ...useData
    };
}
